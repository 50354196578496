/**Angular Libraries **/
import { Component, OnInit, OnDestroy, ElementRef, ViewChild, Inject, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Renderer2 } from '@angular/core';
// tslint:disable-next-line:rxjs-proper-imports
import { of, Subscription } from 'rxjs';

/**Services **/
import { Utilities } from '../../../../../shared/outer-services/utilities.service';
import { StorageService } from '../../../../../shared/outer-services/storage.service';
import { EventDispatchService } from '../../../../../shared/outer-services/event-dispatch.service';
import { CareersJrCommonMethodsService } from '../../careersJr-services/careersJr-common-methods.service';
import { CareersJrDispatchService } from '../../careersJr-services/careersJr-dispatch.service';
import { CareersJrApiCallService } from '../../careersJr-services/careersJr-apicalls.service';
import { CareersJrApiCallClass } from '../../careersJr-constants/model';
import { clusterDetails, asmntImages } from '../../careersJr-constants/cluster-icons';
import { CareersJr_Index_Reducer_Text_State, CareersJr_Buttons_Reducer_Text_State, Occ_Jr_Assessment_Filter_State } from '../../careersJr-state-management/careersJr-state/careersJr-state';
import { delay, take, takeWhile } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { FrameworkConfigState } from '../../../../../state-management/state/main-state';

@Component({
  selector: 'app-careersJr-index',
  templateUrl: './careersJr-index-layout.html',
})
export class CareersJrIndexComponent implements OnInit, OnDestroy {
  showoccclustertitles = true;
  clusterShow = true; /** Show cluster details view */
  titleShow = false; /** Show title details view */
  indexOccId = []; /** Contains OccId */
  indexTitleName = []; /** Contains OccNames */
  inxForBackground = -1;
  search = {
    text: '',
    clusterOrTitle: 0,
    rating: [],
    wages: [],
    edu: [],
    hotjob: [],
    stem: [],
    selfemploy: [],
    math: [],
    locating: [],
    reading: [],
    result: [],
  }; /** Used for searching values */
  filtersearch = {
    text: '',
    clusterOrTitle: 0,
    rating: [],
    wages: [],
    edu: [],
    hotjob: [],
    selfemploy: [],
    stem: [],
    math: [],
    locating: [],
    reading: [],
    result: [],
  }; /** clone for search variable, used while searching */
  expandCard = 999; /** Used to expand card */
  iconInxVal = 0; /** Value changed everytime when user click filter icon */
  searchIcon = -1;
  titleSort = [-1, -1];
  showFilterChange = 0;
  clusterBackground = -1;
  showCareerColorUp = -1; /** To show thumbs up or down */
  column = 'CategoryName';
  enterEventListener;
  subscription1 = new Subscription();
  eventSub = new Subscription();
  occTextSub = new Subscription();
  occIndexReducerSub = new Subscription();
  isCollapsed = false;
  filtericon = ['fa fa-filter', 'fa fa-times'];
  occIndexReduTxt; /** Contain all list */
  occIndexListText; /** Is a variable that is used to store data coming from reducer */
  occTxtVal; /** contain all names */
  clusterValues = {}; /** Contain cluster values */
  langVal; /** Gets user selected language */
  /** -1 loading, 0 data not found, 1 hide image and display data */
  showLoadingOrDataNotFound = -1; /** Todisplay loading or dataNotFound image */
  defaultImg = '/assets/images/sort_none.png';
  sortName = '/assets/images/sort-dec.png';
  searchBox = true;
  showIcon = false;
  filterText;
  langChange = false;
  elmnt = document.getElementById('main-body');
  backAssessmentValue = false; /** Back assessment button */
  filterIconStatus = [];
  filterpipeshow = true;
  filterdownpipeshow = false;
  radioskill;
  radiolevel;
  carrerRedinessLevelCheckedArr = [];
  occclusinx = '';
  compareIndex =
    ''; /**Value is used to store index value either cluster or title */
  compareClusVal =
    ''; /**used to store the indexValue when navigating from compare to index page */
  filterData = [];
  hideBannerImage: boolean;
  asmntFilerText: any;
  subText = [];
  asmntOccList: any;
  asmntImageNames: any;
  filteredList: any;
  frameworkObj: any;
  private focusFilterIcon: ElementRef;
  compActive = true;
  viewType: string = 'cluster';
  resetLangDataStore = true;
  @ViewChild('focusFilter', { static: false }) set content(
    content: ElementRef
  ) {
    if (content) {
      // initially called with undefined
      this.focusFilterIcon = content;
    }
  }
  @ViewChild('filterFocusDefault', { static: false })
  filterFocusDefault: ElementRef;
  @ViewChild('occIndexFocus', { static: true })
  occIndexFocusElement: ElementRef;
  @ViewChild('ratingFocus', { static: false }) ratingFocusElement: ElementRef;
  @ViewChild('scrollUp', { static: true }) scrollUp: ElementRef;
  restrictedAccountType: boolean;

  constructor(
    private router: Router,
    private occstore: Store<CareersJr_Buttons_Reducer_Text_State>,
    private changeDetectorRef: ChangeDetectorRef,
    private utils: Utilities,
    private storageService: StorageService,
    private outereventDispatcher: EventDispatchService,
    private carrersJrApiCall: CareersJrApiCallService,
    public browserDom: Renderer2,
    private careersJrCommonService: CareersJrCommonMethodsService,
    private activatedRoute: ActivatedRoute,
    private occIndexstore: Store<CareersJr_Index_Reducer_Text_State>,
    private careersJrDispatch: CareersJrDispatchService,
    private apiJson: CareersJrApiCallClass,
    private Frameworkstore: Store<FrameworkConfigState>,
    private occAssessment: Store<Occ_Jr_Assessment_Filter_State>,
    @Inject('RESTRICTED_ACCOUNT_TYPE') restrictedAccount: string,
    @Inject('CAREER_IMAGE_URL') public CareersImages: string,
    @Inject('LANDING_IMAGE_URL') public LandingImages: string,
    private titleService: Title
  ) {
    this.asmntImageNames = asmntImages;
    this.storageService.sessionStorageSet('activeSortId', 'fileOccJr');
    if (this.storageService.sessionStorageGet('locationModule')) {
      const occLtidata = JSON.parse(
        this.storageService.sessionStorageGet('locationModule')
      ).componentId.split('|');
      if (occLtidata[1]) {
        this.router.navigate(['../careersJrHeader'], {
          relativeTo: this.activatedRoute,
          queryParams: { occid: occLtidata[1] },
        });
      }
    }
    this.restrictedAccountType =
      this.utils.getDecodedAccessToken().accountType !== restrictedAccount;
    this.hideBannerImage = this.router.url.includes('plan');
    /*careers index call loading*/
    if (
      !this.storageService.sessionStorageGet('CareersJrIndexReducerText') ||
      !this.storageService.sessionStorageGet('CareersJr_Reducer_OccText')
    ) {
      this.careersJrCommonService.OccIndexDispatch();
    }
    /** Get user selected languge */
    this.langVal = this.storageService.sessionStorageGet('langset');
    this.eventSub = this.careersJrDispatch.listen().subscribe((e) => {
      if (e.type === 'languageChanged') {
        /** When user changes language parameter this subscription detected*/
        this.langChange = true;
        this.langVal = this.storageService.sessionStorageGet('langset');
        const routArr = this.router.url.split('/');
        this.ngOnInit();
        this.savedCareerNames();
      }
    });
    /** Below condition is for checking back to assessment button to display*/
    if (this.storageService.sessionStorageGet('inTab')) {
      if (
        this.storageService.sessionStorageGet('inTab') === 'Assess' ||
        this.storageService.sessionStorageGet('inTab') === 'cciAssess' ||
        this.storageService.sessionStorageGet('inTab') === 'osAssess'
      ) {
        this.backAssessmentValue = true;
      } else {
        this.backAssessmentValue = false;
      }
    }
    /** Get data from reducer to display buttons text */
    this.occTextSub = this.occstore
      .select('CareersJr_Buttons_Reducer_Text')
      .subscribe((respButtonstxt) => {
        if (respButtonstxt && respButtonstxt.careersJr_Buttons_Reducer_Text) {
          this.occIndexListText = respButtonstxt;
        }
      });
    this.Frameworkstore.select('config')
      .pipe(take(1))
      .subscribe((res) => {
        if (res.config) {
          const tabId = parseInt(this.storageService.sessionStorageGet('tabId')) - 1;
          this.frameworkObj = res.config.Result.tabItems[tabId].compList.map(
            (obj) => obj.compId
          );
        }
      });
    this.occAssessment
      .select('Occ_Jr_Assessment_Filter_Reducer')
      .subscribe((assFilterText) => {
        if (
          assFilterText &&
          assFilterText.Occ_Jr_Assessment_Filter_Text &&
          assFilterText.Occ_Jr_Assessment_Filter_Text.length
        ) {
          this.asmntFilerText = assFilterText;
          this.asmntFilerText.Occ_Jr_Assessment_Filter_Text =
            this.asmntFilerText.Occ_Jr_Assessment_Filter_Text.filter((val) => {
              return this.frameworkObj.includes('sort' + val.sortId);
            });
          this.titleSort[0] = this.titleSort[0] * -1;
          const ref = this;
          this.asmntFilerText.Occ_Jr_Assessment_Filter_Text.sort(function (
            a,
            b
          ) {
            return a.title.toLowerCase().localeCompare(b.title.toLowerCase());
          });
          this.subText = [];
          if (this.storageService.sessionStorageGet('CareersJr_Reducer_OccText')) {
            const reducerButtonText = JSON.parse(
              this.storageService.sessionStorageGet('CareersJr_Reducer_OccText')
            );
            for (
              let i = 0;
              i < this.asmntFilerText.Occ_Jr_Assessment_Filter_Text.length;
              i++
            ) {
              for (
                let k = 0;
                k <
                reducerButtonText.careersJr_Buttons_Reducer_Text.filters.results
                  .ratings.length;
                k++
              ) {
                if (
                  this.asmntFilerText.Occ_Jr_Assessment_Filter_Text[i].sortId ==
                  reducerButtonText.careersJr_Buttons_Reducer_Text.filters
                    .results.ratings[k].id
                ) {
                  this.subText.push({
                    sortid:
                      reducerButtonText.careersJr_Buttons_Reducer_Text.filters
                        .results.ratings[k].id,
                    text: reducerButtonText.careersJr_Buttons_Reducer_Text
                      .filters.results.ratings[k].title,
                  });
                }
              }
            }
          }
        }
      });
    this.subscription1 = this.activatedRoute.queryParams.subscribe((params) => {
      this.utils.showLoading();
      /** Get occId from parameters */
      this.occclusinx = params['occclusinx'];
      this.compareClusVal = params['compareClusVal'];
      if (this.occclusinx) {
        this.methodfilter(this.occclusinx);
      } else if (this.compareClusVal) {
        this.methodfilter(this.compareClusVal);
      }
      /** Get key value to nameTxt using occID */
    });

    /**
     *? this section for page refresh
     */
    if (
      this.search &&
      this.search.clusterOrTitle &&
      this.search.clusterOrTitle == 0
    ) {
      this.viewType = 'cluster';
    } else {
      this.viewType = 'title';
    }
  }
  ngOnInit() {
    if (this.storageService.sessionStorageGet('filterJrSettings')) {
      this.search = JSON.parse(this.storageService.sessionStorageGet('filterJrSettings'));
    }
    this.titleSort[0] = this.titleSort[0] * -1;
    // Assigning a boolean value as true when the component loads thus filter occurs by title names.
    this.careersJrCommonService.titleView = true;
    this.careersJrCommonService.clusterView = false;
    this.showLoadingOrDataNotFound = -1;
    /* filter array call */
    const settingFilter = new CareersJrApiCallClass();
    settingFilter.method = 'GET';
    settingFilter.endUrl = this.restrictedAccountType
      ? 'OccJr/index'
      : 'OccJr/index/default';
    settingFilter.moduleName = 'Settings/v1/';
    let path_param_json;

    if (this.restrictedAccountType) {
      path_param_json = [
        {
          param_type: 'path',
          params: ['accountID'],
        },
      ];
    } else {
      path_param_json = [];
    }
    const data = {
      input_data: path_param_json,
    };
    settingFilter.method = 'GET';
    settingFilter.data = JSON.stringify(data);
    this.subscription1 = this.carrersJrApiCall
      .callApi([settingFilter])
      .subscribe((respOccindex) => {
        this.filterData = respOccindex[0].Result;
      });

    this.dynamicStyle();
    let defaultCareerTitleView = '0';
    if (this.storageService.sessionStorageGet('filterJrSettings')) {
      this.search = JSON.parse(this.storageService.sessionStorageGet('filterJrSettings'));
      defaultCareerTitleView = this.search.clusterOrTitle.toString();
    } else {
      if (this.storageService.sessionStorageGet('careerJrShow')) {
        defaultCareerTitleView = this.storageService.sessionStorageGet('careerJrShow');
      }
    }
    // Default value is set to Titles in OccIndex page.
    if (defaultCareerTitleView === '0') {
      this.search.clusterOrTitle = 0;
      this.storageService.sessionStorageSet('filterJrSettings', JSON.stringify(this.search));
    } else {
      this.search.clusterOrTitle = 1;
      this.storageService.sessionStorageSet('filterJrSettings', JSON.stringify(this.search));
    }
    this.displayList();
    this.clusterValues = clusterDetails;
    this.showoccclustertitles = true;
    if (parseInt(this.occclusinx, 10) >= 0) {
      this.showoccclustertitles = false;
    }
    /*UseCase: If you make filter selection of listpage move away from the filter, when you go back to the index the filter selections are cleared.*/
    if (JSON.parse(this.storageService.sessionStorageGet('filterJrSettings'))) {
      this.search = JSON.parse(this.storageService.sessionStorageGet('filterJrSettings'));
      if (this.search.math.length) {
        this.carrerRedinessLevelCheckedArr[0] = {
          isCareerChecked: true,
          careerRadiosCheckedArr: [false, false, false, false, false],
          itemid: 'math',
          ritemid: this.search.math,
        };
        this.onRadioChange(0, this.search.math.length - 1, 'math', '', '');
      }
      if (this.search.locating.length) {
        this.carrerRedinessLevelCheckedArr[1] = {
          isCareerChecked: true,
          careerRadiosCheckedArr: [false, false, false, false, false],
          itemid: 'locating',
          ritemid: this.search.locating,
        };
        this.onRadioChange(
          1,
          this.search.locating.length - 1,
          'locating',
          '',
          ''
        );
      }
      if (this.search.reading.length) {
        this.carrerRedinessLevelCheckedArr[2] = {
          isCareerChecked: true,
          careerRadiosCheckedArr: [false, false, false, false, false],
          itemid: 'reading',
          ritemid: this.search.reading,
        };
        this.onRadioChange(
          2,
          this.search.reading.length - 1,
          'reading',
          '',
          ''
        );
      }
      if (this.search.text) {
        this.searchIcon = 1;
      }
    }
    /** Contain cluster list and title list */
    this.occIndexReducerSub = this.occIndexstore
      .select('CareersJr_Index_Reducer_Text')
      .subscribe((respAllList) => {
        // if (this.langChange === true) {
        const val: any = respAllList;
        const isEmptyList = this.occIndexReduTxt === undefined || 
          (this.occIndexReduTxt.CareersJr_Index_Reducer_Text && 
            this.occIndexReduTxt.CareersJr_Index_Reducer_Text.occList && 
            this.occIndexReduTxt.CareersJr_Index_Reducer_Text.occList.length === 0);
        if (val && isEmptyList) {
          this.occIndexReduTxt = respAllList;
          this.setLoadingOrDataNotFound();
          if (this.showLoadingOrDataNotFound === 1) {
            this.savedCareerNames();
          }
        }
        /*Here the perameter is added to filter the data using filter check boxes*/
        this.getResultFilter();
      });

    this.setDocTitle('Career Clusters List');
  }

  //this call is to fill and unfill the thumbs up and down icon
  getUnFill(occIDInfo, thumbVal, headerValue) {
    this.utils.showLoading();
    const { indexvalue, occID, occtitle, rating } = occIDInfo;
    if (thumbVal == 'up' && rating !== 1) {
      this.showCareerColorUp = 1;
    } else if (thumbVal == 'down' && rating !== 0) {
      this.showCareerColorUp = 0;
    } else {
      this.showCareerColorUp = -1;
    }
    this.resultPost(occIDInfo, headerValue);
  }

  resultPost(occIDInfo, headerValue) {
    const { indexvalue, occID, occtitle, rating } = occIDInfo;
    //post thumbs up and down to api
    this.apiJson.method = 'POST';
    this.apiJson.sessionID = this.storageService.sessionStorageGet('auth_key');
    this.apiJson.moduleName = 'OccJr/v1/';
    const thumbsValue = {
      input_data: [
        {
          param_type: 'path',
          params: ['accountID', 'ratings', occID, this.showCareerColorUp],
        },
        {
          param_type: 'query',
          params: { lang: this.langVal },
        },
      ],
    };
    const user = JSON.stringify(thumbsValue);
    this.apiJson.endUrl = 'users';
    this.apiJson.data = user;
    this.carrersJrApiCall
      .callApi([this.apiJson])
      .pipe(takeWhile(() => this.compActive))
      .subscribe(
        (res) => {
          if (res.Result + '' == 'true') {
            const val = JSON.parse(
              this.storageService.sessionStorageGet('CareersJrIndexReducerText')
            );
            if (headerValue) {
              val.CareersJr_Index_Reducer_Text.clusList.map((occ) => {
                if (occ.clusterID === parseInt(occID, 10)) {
                  occ.rating = this.showCareerColorUp;
                }
              });
            } else {
              val.CareersJr_Index_Reducer_Text.occList
                .filter((val) => val.occID === parseInt(occID, 10))
                .map((occ) => {
                  occ.rating = this.showCareerColorUp;
                });
            }
            this.storageService.sessionStorageSet(
              'CareersJrIndexReducerText',
              JSON.stringify(val)
            );
            this.savedCareerNames();
            this.getResultFilter();
            /** Below code is to fix the sort reset issue when thumbsup is clicked */
            const prop = 'value'; // set property to value as we are sorting values from table array
            const ref = this; // referring to current func
            // check if current sorting decending is needed and if yes sort the result
            if (ref.titleSort[0] == -1) {
              const total = val.CareersJr_Index_Reducer_Text.occList.sort(
                function (a, b) {
                  if (a[prop] < b[prop]) {
                    return -1 * ref.titleSort[0];
                  } else if (a[prop] > b[prop]) {
                    return 1 * ref.titleSort[0];
                  }
                }
              );

              val.CareersJr_Index_Reducer_Text.occList = total; // assign sorted values
              this.occIndexReduTxt = val; // assign sorted values
            }
            const evnt = document.createEvent('CustomEvent');
            evnt.initEvent('PlanCompStatus', true, true);
            this.outereventDispatcher.dispatch(evnt);
          }
          this.utils.hideLoading();
        },
        (e) =>
          this.utils.handleError(
            `carersJr-index-component.ts resultPost: ${e.message}`
          )
      );
  }
  /**
   * This method is used for filterchange
   * @param eve
   * @param carInx
   * @param itemid
   */
  onFilterChange(eve, carInx, itemid) {
    // The list of items in an array is initially made false statically because they are like rating level check boxes so we need them to be unchecked by default. If we don't assign false statically then the check boxes are not working correctly when we do the process from component file.
    if (eve.target.checked) {
      this.carrerRedinessLevelCheckedArr[carInx] = {
        isCareerChecked: true,
        careerRadiosCheckedArr: [false, false, false, false, false],
        itemid: itemid,
        ritemid: [],
      };
    } else {
      this.carrerRedinessLevelCheckedArr[carInx] = {
        isCareerChecked: false,
        careerRadiosCheckedArr: [false, false, false, false, false],
        itemid: '',
        ritemid: [],
      };
    }
  }
  onRadioChange(careerInx, careerradioInx, itemid, itemtitle, eve) {
    // if (event.target.checked) {
    for (
      let i = 0;
      i <
      this.carrerRedinessLevelCheckedArr[careerInx].careerRadiosCheckedArr
        .length;
      i++
    ) {
      if (i <= careerradioInx) {
        this.carrerRedinessLevelCheckedArr[careerInx].careerRadiosCheckedArr[
          i
        ] = true;
      } else {
        this.carrerRedinessLevelCheckedArr[careerInx].careerRadiosCheckedArr[
          i
        ] = false;
      }
    }
    this.carrerRedinessLevelCheckedArr[careerInx].ritemid = [];
    for (
      let j = 0;
      j <
      this.occIndexListText.careersJr_Buttons_Reducer_Text.filters.readiness
        .right.ratings[careerInx].levels.length;
      j++
    ) {
      if (j <= careerradioInx) {
        this.carrerRedinessLevelCheckedArr[careerInx].ritemid.push(
          this.occIndexListText.careersJr_Buttons_Reducer_Text.filters.readiness
            .right.ratings[careerInx].levels[j].id
        );
      }
    }
    //Rating checkbox accessibility
    if (!this.ratingFocusElement) {
      return;
    }
    if (eve.target.checked) {
      this.ratingFocusElement.nativeElement
        .querySelector('#ratingreadiness' + itemtitle + '' + itemid)
        .setAttribute('aria-label', itemid + ' Rating Checkbox not checked');
    } else {
      this.ratingFocusElement.nativeElement
        .querySelector('#ratingreadiness' + itemtitle + '' + itemid)
        .setAttribute('aria-label', itemid + ' Rating Checkbox checked');
    }
  }
  occBtnTxt() {
    try {
      if (this.langChange === false) {
        const val = JSON.parse(
          this.storageService.sessionStorageGet('CareersJr_Reducer_OccText')
        );
        if (
          val &&
          val.careersJr_Buttons_Reducer_Text &&
          val.careersJr_Buttons_Reducer_Text.common
        ) {
          this.occIndexListText = val;
        }
      }
      if (
        this.occIndexListText &&
        this.occIndexListText.careersJr_Buttons_Reducer_Text &&
        this.occIndexListText.careersJr_Buttons_Reducer_Text.common
      ) {
        this.filterText =
          this.occIndexListText.careersJr_Buttons_Reducer_Text.common.filter.split(
            ':'
          )[0];
      }
      if (
        this.occIndexListText &&
        this.occIndexListText.careersJr_Buttons_Reducer_Text &&
        this.occIndexListText.careersJr_Buttons_Reducer_Text.filters
      ) {
        for (
          let i = 0;
          i <
          this.occIndexListText.careersJr_Buttons_Reducer_Text.filters.readiness
            .left.ratings.length;
          i++
        ) {
          this.carrerRedinessLevelCheckedArr.push({
            isCareerChecked: false,
            careerRadiosCheckedArr: [false, false, false, false, false],
          });
        }
      }
    } catch (e) {
      this.utils.handleError(
        `careersJr-index-component.ts occBtnTxt: ${e.message}`
      );
    }
  }

  savedCareerNames() {
    const val = JSON.parse(this.storageService.sessionStorageGet('CareersJrIndexReducerText'));
    let value = true;
    this.indexOccId = [];
    this.indexTitleName = [];
    if (val && val.CareersJr_Index_Reducer_Text) {
      this.occIndexReduTxt = val;
      this.utils.hideLoading();
      this.setLoadingOrDataNotFound();
      this.occBtnTxt();
    }
    if (this.storageService.sessionStorageGet('savedValId') !== '') {
      val.CareersJr_Index_Reducer_Text.occList.forEach((respOccList) => {
        if (
          respOccList.occID ===
            parseInt(this.storageService.sessionStorageGet('savedValId'), 10) &&
          value === true
        ) {
          if (
            this.indexOccId.indexOf(
              parseInt(this.storageService.sessionStorageGet('savedValId'), 10)
            ) === -1
          ) {
            this.indexOccId.push(
              parseInt(this.storageService.sessionStorageGet('savedValId'), 10)
            );
            this.indexTitleName.push(respOccList.title);
            value = false;
          }
        }
      });
    }
  }
  scrollTop() {
    this.showIcon = false;
    this.elmnt.scrollTop = 0;
  }
  /**
   * This method is used for the title bar clicking
   * @param property
   * @param inx
   */
  titleBarClicked(property, inx) {
    this.clusterBackground = -1;
    this.inxForBackground = inx;
    this.column = property;

    let val;
    if (this.searchIcon === 1 || this.showFilterChange === 1) {
      val = this.occIndexReduTxt;
    } else {
      val = JSON.parse(this.storageService.sessionStorageGet('CareersJrIndexReducerText'));
    }
    this.commonsortFunction(property, val);
  }
  commonsortFunction(property, val) {
    const ClusterIdList = [];
    const CluList = [];
    let total;
    this.titleSort[0] = this.titleSort[0] * -1;
    const ref = this;
    if (property !== 'wages') {
      if (property === 'HotJobs') {
        total = val.CareersJr_Index_Reducer_Text.occList.sort(function (x, y) {
          // true values first
          if (x['hotJob'] > y['hotJob']) {
            return 1 * ref.titleSort[0];
          } else if (x['hotJob'] < y['hotJob']) {
            return -1 * ref.titleSort[0];
          }
        });
      } else {
        total = val.CareersJr_Index_Reducer_Text.occList.sort(function (a, b) {
          if (a[property] < b[property]) {
            return -1 * ref.titleSort[0];
          } else if (a[property] > b[property]) {
            return 1 * ref.titleSort[0];
          }
        });
      }
    } else {
      const property1 = 'occID';
      total = val.CareersJr_Index_Reducer_Text.occList.sort(function (a, b) {
        const aVal = parseInt(
          a[property].replace(/[^a-zA-Z0-9]/g, '').replace(/[^0-9]/g, 0),
          10
        );
        const bVal = parseInt(
          b[property].replace(/[^a-zA-Z0-9]/g, '').replace(/[^0-9]/g, 0),
          10
        );
        if (aVal < bVal) {
          return -1 * ref.titleSort[0];
        } else if (aVal > bVal) {
          return 1 * ref.titleSort[0];
        }
      });
    }
    val.CareersJr_Index_Reducer_Text.clusList.forEach(
      function (obj, inx) {
        total.forEach(
          function (obj1, inx1) {
            if (obj.clusterID === obj1.clusterID) {
              if (ClusterIdList.indexOf(obj.clusterID) === -1) {
                ClusterIdList.push(obj.clusterID);
                CluList.push(obj);
              }
            }
          }.bind(this)
        );
      }.bind(this)
    );

    val.CareersJr_Index_Reducer_Text.clusList = CluList;
    val.CareersJr_Index_Reducer_Text.occList = total;
    this.occIndexReduTxt = val;
  }
  /**
   * This method is used for cluster bar clicking
   * @param property
   * @param inx
   */
  clusterBarClicked(property, inx) {
    this.inxForBackground = -1;
    this.clusterBackground = inx;
    this.column = property;
    this.titleSort[1] = this.titleSort[1] * -1;
    const ref = this;
    const ClusterIdList = [];
    const CluList = [];
    const val = this.occIndexReduTxt;
    let total;
    if (this.showoccclustertitles) {
      total = val.CareersJr_Index_Reducer_Text.clusList.sort(function (a, b) {
        if (a[property] < b[property]) {
          return -1 * ref.titleSort[1];
        } else if (a[property] > b[property]) {
          return 1 * ref.titleSort[1];
        } else {
          return 0;
        }
      });

      val.CareersJr_Index_Reducer_Text.clusList = total;
    }
    this.occIndexReduTxt = val;
    this.commonsortFunction(property, val);
  }
  dynamicStyle() {
    const ref = this;
    this.enterEventListener = function (event) {
      try {
        if (document.querySelector('.tooltip')) {
          ref.browserDom.setStyle(
            document.querySelector('.tooltip'),
            'display',
            'none'
          );
        }
        const sticky = document.getElementById('fixScreenid');
        const sticky1 = sticky.offsetTop;
        window.onresize = function () {
          ref.showIcon = false;
          ref.elmnt.scrollTop = 0;
        };
        if (window.innerWidth >= 320) {
          ref.showIcon = false;
          if (Math.ceil(ref.elmnt.scrollTop) < sticky1) {
            for (let i = 0; i < 3; i++) {
              ref.browserDom.removeStyle(
                document.querySelector('.fixScreen' + i),
                'position'
              );
              ref.browserDom.removeStyle(
                document.querySelector('.fixScreen' + i),
                'top'
              );
              ref.browserDom.removeStyle(
                document.querySelector('.fixScreen' + i),
                'background'
              );
              ref.browserDom.removeStyle(
                document.querySelector('.fixScreen' + i),
                'z-index'
              );
              ref.browserDom.removeStyle(
                document.querySelector('.fixScreen' + i),
                'padding-top'
              );
            }
          } else {
            const fixSrn = document.getElementById('fixScreenid').offsetHeight;
            const val1 = fixSrn + 'px';
            for (let i = 0; i < 3; i++) {
              if (i === 0) {
                ref.browserDom.setStyle(
                  document.querySelector('.fixScreen' + i),
                  'top',
                  '0px'
                );
                ref.browserDom.setStyle(
                  document.querySelector('.fixScreen' + i),
                  'background',
                  'white'
                );
                ref.browserDom.setStyle(
                  document.querySelector('.fixScreen' + i),
                  'padding-top',
                  '24px'
                );
              } else {
                ref.browserDom.setStyle(
                  document.querySelector('.fixScreen' + i),
                  'top',
                  val1
                );
              }
              ref.browserDom.setStyle(
                document.querySelector('.fixScreen' + i),
                'position',
                'sticky'
              );
              ref.browserDom.setStyle(
                document.querySelector('.fixScreen' + i),
                'z-index',
                '999'
              );
            }
          }
        } else {
          if (Math.ceil(ref.elmnt.scrollTop) < sticky1) {
            ref.showIcon = false;
            for (let i = 0; i < 3; i++) {
              ref.browserDom.removeStyle(
                document.querySelector('.fixScreen' + i),
                'position'
              );
              ref.browserDom.removeStyle(
                document.querySelector('.fixScreen' + i),
                'top'
              );
              ref.browserDom.removeStyle(
                document.querySelector('.fixScreen' + i),
                'background'
              );
              ref.browserDom.removeStyle(
                document.querySelector('.fixScreen' + i),
                'z-index'
              );
              ref.browserDom.removeStyle(
                document.querySelector('.fixScreen' + i),
                'padding-top'
              );
            }
          } else {
            ref.showIcon = true;
          }
        }
      } catch (e) {
        console.log('error in occlist block--->' + e.message);
      }
    };
    document.addEventListener('scroll', this.enterEventListener, true);
  }
  /**
   * Display filter values in div
   * @param filtername
   * @param val
   */
  setFilterVal(filtername, val) {
    let isAvailable = false;
    let inx1 = -1;
    for (let i = 0; i < this.search[filtername].length; i++) {
      if (val === this.search[filtername][i]) {
        isAvailable = true;
        inx1 = i;
        break;
      } else {
        isAvailable = false;
      }
    }

    if (isAvailable === false) {
      this.search[filtername].push(val);
      isAvailable = true;
    } else {
      this.search[filtername].splice(inx1, 1);
    }
  }
  /** This method is called whenever we are trying to search */
  changeText() {
    this.isCollapsed = false;
    this.filterText = 'Filter';
    if (this.searchIcon === -1) {
      this.searchIcon = 1;
    }
    if (this.search.text === '') {
      this.searchIcon = -1;
    }
    this.occIndexReduTxt = this.careersJrCommonService.filterListData(
      {
        text: this.search.text,
        edu: this.search.edu,
        rating: this.search.rating,
        wages: this.search.wages,
        hotjob: this.search.hotjob,
        stem: this.search.stem,
        result: this.search.result,
        selfemploy: this.search.selfemploy,
        math: this.search.math,
        locating: this.search.locating,
        reading: this.search.reading,
        module: 'occ_index',
      },
      'index'
    );
    this.setLoadingOrDataNotFound();
    this.iconInxVal = 0;
    this.searchBox = true;
    this.storageService.sessionStorageSet('filterJrSettings', JSON.stringify(this.search));
  }
  dismissSearch() {
    this.searchIcon = 0;
    this.search.text = '';
    this.storageService.sessionStorageRemove('filterJrSettings');
    this.changeText();
  }
  /**
   * This method is called when the user clicks on either career or emerge
   * @param inx1
   * @param occID
   * @param occName
   * @param clusterId
   */
  callOccDetail(occinfo) {
    const { indexval, occID, occName, clusterId } = occinfo;
    this.resetLangDataStore = false;
    this.storageService.sessionStorageSet('personalQua', 'no');
    /*Here the logic is store the filter Jr settings json like interest,hotjob,stem*/
    this.storageService.sessionStorageSet('filterJrSettings', JSON.stringify(this.search));
    if (
      this.indexOccId.length < 2 ||
      (this.indexOccId.length === 2 && this.indexOccId.indexOf(occID) !== -1)
    ) {
      let clusterIcon;
      let backGroundClr;
      const clsVal = this.careersJrCommonService.clustDetails(clusterId);
      clusterIcon = clsVal.clusterIconValue;
      backGroundClr = clsVal.clusterBgColor;
      const twoDigit = occID.toString().substr(0, 2);
      if (parseInt(twoDigit, 10) === 24) {
        this.router.navigate(['../careersJrEmergHeader'], {
          relativeTo: this.activatedRoute,
          queryParams: {
            occid: occID,
            occname: occName,
            clusIcon: clusterIcon,
            clusColor: backGroundClr,
            inx: indexval,
          },
        });
      } else {
        this.router.navigate(['../careersJrHeader'], {
          relativeTo: this.activatedRoute,
          queryParams: { occid: occID, occname: occName, inx: indexval },
        });
      }
    }
  }
  /**
   * This method is called when the user clicks on the cluster
   * @param clusterId
   * @param ClusterName
   */
  callOccDetailCluster(clusterinfo) {
    const { clusterIndex, clusterId, ClusterName } = clusterinfo;
    /*Here the logic is store the filter Jr settings json like interest,hotjob,stem*/
    this.storageService.sessionStorageSet('filterJrSettings', JSON.stringify(this.search));
    if (
      this.indexOccId.length < 2 ||
      (this.indexOccId.length === 2 &&
        this.indexOccId.indexOf(clusterId) !== -1)
    ) {
      this.utils.showLoading();
      let clusterIcon;
      let backGroundClr;
      const clsVal = this.careersJrCommonService.clustDetails(clusterId);

      clusterIcon = clsVal.clusterIconValue;
      backGroundClr = clsVal.clusterBgColor;
      this.router.navigate(['../careersJrClusterHeader'], {
        relativeTo: this.activatedRoute,
        queryParams: {
          clusId: clusterId,
          clusName: ClusterName,
          clusIcon: clusterIcon,
          clusColor: backGroundClr,
          inx: clusterIndex,
        },
      });
    }
  }

  displayList() {
    if (this.search.clusterOrTitle != 1) {
      this.clusterShow = true;
      this.titleShow = false;
    } else {
      this.clusterShow = false;
      this.titleShow = true;
    }
  }
  /** Cluster or career hide */
  clusterListFun(show) {
    this.utils.showLoading();
    this.viewType = show;
    this.elmnt.scrollTop = 0;
    if (show === 'cluster') {
      this.search.clusterOrTitle = 0;
      this.careersJrCommonService.clusterView = true;
      this.careersJrCommonService.titleView = false;
      this.getResultFilter();
      this.setDocTitle('Career Clusters List');
    } else if (show === 'title') {
      this.search.clusterOrTitle = 1;
      this.careersJrCommonService.titleView = true;
      this.careersJrCommonService.clusterView = false;
      this.getResultFilter();
      this.setDocTitle('Careers List');
    }
    this.storageService.sessionStorageSet('filterJrSettings', JSON.stringify(this.search));
    this.displayList();
    this.utils.hideLoading();
  }
  /** Executes when clicked on career to get result for selected options */
  getResultFilter() {
    let aFilterSelected = 0;
    this.filterIconStatus = [];
    this.inxForBackground = -1;
    this.clusterBackground = -1;
    this.filtersearch = {
      text: '',
      clusterOrTitle: 0,
      rating: [],
      wages: [],
      edu: [],
      hotjob: [],
      stem: [],
      selfemploy: [],
      math: [],
      locating: [],
      reading: [],
      result: [],
    };
    this.filtersearch.text = this.search.text;
    let hover = '';
    hover = this.occIndexListText &&
        this.occIndexListText.careersJr_Buttons_Reducer_Text &&
        this.occIndexListText.careersJr_Buttons_Reducer_Text.filters &&
        this.occIndexListText.careersJr_Buttons_Reducer_Text.filters.user ?
        this.occIndexListText.careersJr_Buttons_Reducer_Text.filters.user.title : null;

    if (this.search.rating.length > 0) {
      this.search.rating.forEach(
        function (obj, inx) {
          this.filtersearch.rating.push(obj);
        }.bind(this)
      );
      aFilterSelected = 1;
      this.filterIconStatus.push({
        image: this.CareersImages + 'thumbs-up-hover.svg',
        filter: 'rating',
        hover
      });
    }
    if (this.search.wages.length > 0) {
      this.search.wages.forEach(
        function (obj, inx) {
          this.filtersearch.wages.push(obj);
        }.bind(this)
      );
      aFilterSelected = 1;
      this.filterIconStatus.push({
        image: this.CareersImages + 'finance.png',
        filter: 'wages',
        hover: hover ? this.occIndexListText.careersJr_Buttons_Reducer_Text.filters.wages.title : null
      });
    }
    if (this.search.edu.length > 0) {
      this.search.edu.forEach(
        function (obj, inx) {
          this.filtersearch.edu.push(obj);
        }.bind(this)
      );
      aFilterSelected = 1;
      this.filterIconStatus.push({
        image: this.CareersImages + 'education.png',
        filter: 'edu',
        hover: hover ? this.occIndexListText.careersJr_Buttons_Reducer_Text.filters.edLevel.title : null
      });
    }
    if (this.search.hotjob.length > 0) {
      this.search.hotjob.forEach(
        function (obj, inx) {
          this.filtersearch.hotjob.push(obj);
        }.bind(this)
      );
      aFilterSelected = 1;
      this.filterIconStatus.push({
        image: this.CareersImages + 'hot-jobs.png',
        filter: 'hotjob',
        hover: hover ? this.occIndexListText.careersJr_Buttons_Reducer_Text.filters.hotJobs.title : null
      });
    }
    if (this.search.stem.length > 0) {
      this.search.stem.forEach(
        function (obj, inx) {
          this.filtersearch.stem.push(obj);
        }.bind(this)
      );
      aFilterSelected = 1;
      this.filterIconStatus.push({
        image: this.CareersImages + 'stem.png',
        filter: 'stem',
        hover: hover ? this.occIndexListText.careersJr_Buttons_Reducer_Text.filters.stem.title : null
      });
    }
    if (this.search.selfemploy.length > 0) {
      this.search.selfemploy.forEach(
        function (obj, inx) {
          this.filtersearch.selfemploy.push(obj);
        }.bind(this)
      );
      aFilterSelected = 1;
      this.filterIconStatus.push({
        image: this.LandingImages + 'enterpreneurial-icon.png',
        filter: 'selfemploy',
        hover: hover ? this.occIndexListText.careersJr_Buttons_Reducer_Text.filters.selfEmploy.title : null
      });
    }
    if (this.search.result.length > 0) {
      this.search.result.forEach(
        function (obj, inx) {
          this.filtersearch.result.push(obj);
        }.bind(this)
      );
      aFilterSelected = 1;
      this.search.result.map((obj) => {
        let images;
        images = this.asmntImageNames[obj];
        this.filterIconStatus.push({
          image: this.LandingImages + images,
          filter: 'result',
          sortType: obj,
          hover: hover ? (this.asmntFilerText.Occ_Jr_Assessment_Filter_Text.find(r => r.sortId === obj)).title : null
        });
      });
    }
    this.filtersearch.math = [];
    this.filtersearch.locating = [];
    this.filtersearch.reading = [];
    this.search.math = [];
    this.search.locating = [];
    this.search.reading = [];

    for (let i = 0; i < this.carrerRedinessLevelCheckedArr.length; i++) {
      if (this.carrerRedinessLevelCheckedArr[i].itemid === 'math') {
        this.search.math = this.carrerRedinessLevelCheckedArr[i].ritemid;
      }
      if (this.carrerRedinessLevelCheckedArr[i].itemid === 'locating') {
        this.search.locating = this.carrerRedinessLevelCheckedArr[i].ritemid;
      }
      if (this.carrerRedinessLevelCheckedArr[i].itemid === 'reading') {
        this.search.reading = this.carrerRedinessLevelCheckedArr[i].ritemid;
      }
    }
    if (
      this.search.reading.length > 0 ||
      this.search.locating.length > 0 ||
      this.search.math.length > 0
    ) {
      this.filterIconStatus.push({
        image: this.CareersImages + 'Career-Readiness-Levels.png',
        filter: 'careerLevel',
      });
    }
    if (this.search.math.length > 0) {
      this.search.math.forEach(
        function (obj, inx) {
          this.filtersearch.math.push(obj);
        }.bind(this)
      );
      aFilterSelected = 1;
    }
    if (this.search.locating.length > 0) {
      this.search.locating.forEach(
        function (obj, inx) {
          this.filtersearch.locating.push(obj);
        }.bind(this)
      );
      aFilterSelected = 1;
    }
    if (this.search.reading.length > 0) {
      this.search.reading.forEach(
        function (obj, inx) {
          this.filtersearch.reading.push(obj);
        }.bind(this)
      );
      aFilterSelected = 1;
    }

    this.filteredList = this.careersJrCommonService.filterListData(
      {
        text: this.search.text,
        edu: this.search.edu,
        rating: this.search.rating,
        wages: this.search.wages,
        hotjob: this.search.hotjob,
        stem: this.search.stem,
        selfemploy: this.search.selfemploy,
        result: this.search.result,
        math: this.search.math,
        locating: this.search.locating,
        reading: this.search.reading,
        module: 'occ_index',
      },
      'index'
    );

    if (this.search.result.length) {
      this.apiJson.method = 'POST';
      this.apiJson.sessionID = this.storageService.sessionStorageGet('auth_key');
      this.apiJson.endUrl = 'Users';
      this.apiJson.moduleName = 'Assessment/v1/';
      const data = {
        input_data: [
          {
            param_type: 'path',
            params: ['AssessmentOccs', 'accountID'],
          },
          {
            param_type: 'query',
            params: {},
          },
          {
            param_type: 'body',
            params: this.search.result,
          },
        ],
      };
      const dat = JSON.stringify(data);
      this.apiJson.data = dat;
      this.carrersJrApiCall
        .callApi([this.apiJson])
        .pipe(takeWhile(() => this.compActive))
        .subscribe((res) => {
          this.asmntOccList = res.Result.map((obj) => obj.occID + 100000);
          this.filteredList.CareersJr_Index_Reducer_Text.occList =
            this.filteredList.CareersJr_Index_Reducer_Text.occList.filter(
              (val) => {
                return this.asmntOccList.includes(val.occID);
              }
            );
          this.filteredList.CareersJr_Index_Reducer_Text.clusList =
            this.filteredList.CareersJr_Index_Reducer_Text.clusList.filter(
              (val) => {
                const occList =
                  this.filteredList.CareersJr_Index_Reducer_Text.occList.find(
                    (obj) => obj.clusterID == val.clusterID
                  );
                if (occList) {
                  return val;
                }
              }
            );
          this.occIndexReduTxt = this.filteredList;
        });
    } else {
      this.occIndexReduTxt = this.filteredList;
    }
    this.setLoadingOrDataNotFound();
    if (aFilterSelected === 1) {
      this.showFilterChange = 1;
      this.filterpipeshow = false;
      this.filterdownpipeshow = true;
      this.changeDetectorRef.detectChanges();
      if (this.focusFilterIcon) {
        this.focusFilterIcon.nativeElement.setAttribute('tabindex', '0');
        this.focusFilterIcon.nativeElement.focus();
      }
    } else {
      this.showFilterChange = 0;
      this.filterpipeshow = true;
      this.filterdownpipeshow = false;
    }
    this.storageService.sessionStorageSet('filterJrSettings', JSON.stringify(this.search));
    this.iconInxVal = 0;
    this.searchBox = true;
    this.isCollapsed = !this.isCollapsed;
    this.filterText =
      this.occIndexListText &&
      this.occIndexListText.careersJr_Buttons_Reducer_Text &&
      this.occIndexListText.careersJr_Buttons_Reducer_Text.common
        ? this.occIndexListText.careersJr_Buttons_Reducer_Text.common.filter.split(
            ':'
          )[0]
        : '';

    if (this.filterdownpipeshow && this.focusFilterIcon) {
      this.focusFilterIcon.nativeElement.setAttribute('tabindex', '0');
      this.focusFilterIcon.nativeElement.focus();
    }
    if (this.filterpipeshow && this.filterFocusDefault) {
      this.filterFocusDefault.nativeElement.setAttribute('tabindex', '0');
      this.filterFocusDefault.nativeElement.focus();
    }

    // To solve the header missing issue by scrolling to the header part
    const sticky = document.getElementById('fixScreenid');
    const sticky1 = sticky.offsetTop;
    this.elmnt.scrollTop = sticky1;
    this.displayList();
  }
  setLoadingOrDataNotFound() {
    var occCount = this.storageService.sessionStorageGet('parentCnt');
    if (!occCount || occCount === '') {
      this.showLoadingOrDataNotFound = -1;
    } else if (occCount === 'true') {
      this.showLoadingOrDataNotFound = this.occIndexReduTxt
        .CareersJr_Index_Reducer_Text.occList.length > 0 ? 1 : 0;
    } else if (occCount === 'false') {
      this.showLoadingOrDataNotFound = 0;
    }
  }
  /** Reset the selected option */
  resetCheckBoxes() {
    this.showFilterChange = 0;
    this.searchIcon = -1;
    this.inxForBackground = -1;
    this.clusterBackground = -1;
    this.search = {
      text: '',
      clusterOrTitle: 0,
      rating: [],
      wages: [],
      edu: [],
      hotjob: [],
      stem: [],
      selfemploy: [],
      math: [],
      locating: [],
      reading: [],
      result: [],
    };
    this.filtersearch = {
      text: '',
      clusterOrTitle: 0,
      rating: [],
      wages: [],
      edu: [],
      hotjob: [],
      stem: [],
      selfemploy: [],
      math: [],
      locating: [],
      reading: [],
      result: [],
    };
    this.radioskill = '';
    this.radiolevel = '';
    this.storageService.sessionStorageRemove('filterJrSettings');

    this.filterIconStatus = [];
    this.filterpipeshow = true;
    this.filterdownpipeshow = false;
    for (let i = 0; i < 3; i++) {
      this.carrerRedinessLevelCheckedArr[i] = {
        isCareerChecked: false,
        careerRadiosCheckedArr: [false, false, false, false, false],
        itemid: '',
        ritemid: [],
      };
    }
    const val = JSON.parse(this.storageService.sessionStorageGet('CareersJrIndexReducerText'));
    this.setLoadingOrDataNotFound();
  }
  //hide the filter modal on tab navigation to reset button
  hideOnTabReset() {
    this.iconInxVal = 1;
    this.showHideIcon();
  }
  /**
   * This method executes when the user clicks on checkbox
   * @param id
   * @param name
   * @param e
   */
  checkCareer(inx, id, name, e) {
    this.compareIndex = inx;
    if (e === 'click' && this.indexTitleName.length <= 2) {
      let isAvailable = false;
      let inx1 = -1;
      for (let i = 0; i < this.indexOccId.length; i++) {
        if (id === this.indexOccId[i]) {
          isAvailable = true;
          inx1 = i;
          break;
        } else {
          isAvailable = false;
        }
      }
      if (isAvailable === false) {
        this.indexOccId.push(id);
        this.indexTitleName.push(name);
        isAvailable = true;
      } else {
        this.indexOccId.splice(inx1, 1);
        this.indexTitleName.splice(inx1, 1);
        if (this.indexOccId.length === 0) {
          this.storageService.sessionStorageSet('savedValId', '');
        }
      }
    }
  }
  /** Navigate to compare screen */
  CompareOccupations() {
    try {
      this.router.navigate(['../careerJrcompare'], {
        relativeTo: this.activatedRoute,
        queryParams: {
          occId: this.indexOccId[0] + '&' + this.indexOccId[1],
          occName: this.indexTitleName[0] + '&' + this.indexTitleName[1],
          indexValue: this.compareIndex,
        },
      });
    } catch (e) {
      console.log('error--->' + e.message);
    }
  }
  /** Goes to result page of assessment from where we came */
  backAssessment() {
    const occback = this.storageService.sessionStorageGet('occback');
    if (
      this.storageService.sessionStorageGet('inTab') !== 'cciAssess' &&
      this.storageService.sessionStorageGet('inTab') !== 'osAssess' &&
      occback !== 'yes'
    ) {
      this.router.navigate(
        ['../../' + this.storageService.sessionStorageGet('prevRoute') + '/occlist'],
        { relativeTo: this.activatedRoute }
      );
    } else if (occback === 'yes') {
      this.router.navigate(
        ['../../' + this.storageService.sessionStorageGet('prevRoute') + '/chooseCareer'],
        {
          relativeTo: this.activatedRoute,
          queryParams: { occclusinx: this.compareClusVal },
        }
      );
    } else {
      this.router.navigate(
        ['../../' + this.storageService.sessionStorageGet('prevRoute') + '/result'],
        { relativeTo: this.activatedRoute }
      );
    }
  }
  showHideIcon() {
    if (this.iconInxVal === 0) {
      this.iconInxVal = 1;
      this.filterText =
        this.occIndexListText.careersJr_Buttons_Reducer_Text.common.close;
      this.searchBox = false;
      this.isCollapsed = true;
      this.clusterShow = false;
      this.titleShow = false;
    } else {
      this.iconInxVal = 0;
      this.filterText =
        this.occIndexListText.careersJr_Buttons_Reducer_Text.common.filter.split(
          ':'
        )[0];
      this.filtersearch.text = this.search.text;
      this.searchBox = true;
      this.isCollapsed = false;
      this.displayList();
      //prevent a delay on click of filter close button
      of('data')
        .pipe(delay(100))
        .subscribe((data) => {
          this.occIndexReduTxt = JSON.parse(
            this.storageService.sessionStorageGet('CareersJrIndexReducerText')
          );
          this.browserDom.removeStyle(
            document.querySelector('.fixScreen1'),
            'position'
          );
          this.browserDom.removeStyle(
            document.querySelector('.fixScreen1'),
            'top'
          );
          this.browserDom.removeStyle(
            document.querySelector('.fixScreen2'),
            'position'
          );
          this.browserDom.removeStyle(
            document.querySelector('.fixScreen2'),
            'top'
          );
        });
    }
    if (this.scrollUp) {
      this.scrollUp.nativeElement.scrollIntoView();
    }
  }
  /** Expand or closes the card */
  methodfilter(valfil) {
    if (this.expandCard != valfil) {
      this.showoccclustertitles = false;
      this.expandCard = valfil;
    } else {
      this.showoccclustertitles = true;
      this.expandCard = 999;
    }
  }
  /* This function is to remove the filtered items */
  removeFilter(item) {
    if (item.filter === 'careerLevel') {
      this.search.math = [];
      this.search.reading = [];
      this.search.locating = [];
      for (let i = 0; i < 3; i++) {
        this.carrerRedinessLevelCheckedArr[i] = {
          isCareerChecked: false,
          careerRadiosCheckedArr: [false, false, false, false, false],
          itemid: '',
          ritemid: [],
        };
      }
    } else if (item.filter === 'result') {
      this.search.result = this.search.result.filter(
        (val) => val != item.sortType
      );
    } else {
      this.search[item.filter] = [];
    }
    this.getResultFilter();
  }
  /** ngOnDestroy function is called when the component destroys */
  ngOnDestroy() {
    document.removeEventListener('scroll', this.enterEventListener, true);
    this.eventSub.unsubscribe();
    this.occTextSub.unsubscribe();
    this.subscription1.unsubscribe();
    window.onresize = null;
    this.occIndexReducerSub.unsubscribe();
    this.compActive = false;
    this.storageService.removeSessionIndexes(this.resetLangDataStore);
  }
  //track and improve the performance,Check trackByArtNo in template
  trackByArtNo(index: number, occIndexReduTxt: any): string {
    return occIndexReduTxt.rating;
  }
  public setDocTitle(title: string) {
    this.titleService.setTitle(title + ' - CIS360 Careers');
  }
}
